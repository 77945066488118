import Image from "next/image";
import type React from "react";
import { Reveal } from "../revealPageItems";
import { TextRevealByWord } from "../textRevealPasta";

const Section6: React.FC = () => {
  return (
    <div
      id="sobre-nos"
      className="mx-auto flex w-screen flex-col items-center px-4 py-40 md:max-w-[1216px] md:px-0"
    >
      <div className="flex w-full flex-col justify-between md:flex-row md:px-0">
        <div className="flex max-w-[700px] flex-col items-start justify-between">
          <div className="flex flex-col gap-4">
            <div className="pb-2 text-start text-2xl leading-relaxed md:text-4xl md:leading-normal">
              {/* Passando os textos como um array, animação contínua */}
              <TextRevealByWord
                texts={[
                  "Consultas rápidas e seguras, com médicos especializados prontos para atender suas necessidades.",
                  "Seja para dores, ansiedade ou outras condições de saúde...",
                ]}
              />
            </div>
          </div>
          <Reveal>
            {/* O ref é usado para o IntersectionObserver monitorar este elemento */}
            <div className="flex flex-col gap-16 py-16 md:flex-row md:py-0">
              <div>
                {/* Usando o CountUp para animar os números */}
                <div className="flex flex-row items-center gap-2 pb-2 text-6xl font-bold text-brand">
                  <p>+</p>
                  <p>25.000</p>
                </div>
                <p className="text-gray-400">consultas realizadas</p>
              </div>
              <div>
                <div className="flex flex-row items-center gap-2 pb-2 text-6xl font-bold text-brand">
                  <p>+</p>
                  <p>20.000</p>
                </div>
                <p className="text-gray-400">tratamentos iniciados</p>
              </div>
            </div>
          </Reveal>
        </div>
        <Reveal>
          <div className="flex flex-col items-end rounded-2xl bg-green-50 md:w-[350px]">
            <div className="flex flex-col gap-8 p-8">
              <h1 className="text-2xl leading-relaxed">
                Estamos atuando em todo território nacional
              </h1>
              <div className="flex flex-col">
                <Image
                  src="/image/group.png"
                  alt="Estrelas"
                  loading="lazy"
                  width={90}
                  height={30}
                  className="h-[30px] w-[90px]"
                />
                <p className="pt-2">+2000 cidades atendidas</p>
              </div>
            </div>
            <Image
              src="/image/brazel.png"
              alt="Estrelas"
              loading="lazy"
              width={300}
              height={404}
              className="h-[404px] w-[300px]"
            />
          </div>
        </Reveal>
      </div>
      <Reveal>
        <div className="mt-24 flex overflow-x-auto rounded-2xl bg-[#F1F1F1] py-8">
          <div className="flex flex-row gap-8">
            <Image
              src="/image/group-6.png"
              alt="company"
              loading="lazy"
              width={245}
              height={87}
              className="h-auto w-auto"
            />
            <Image
              src="/image/group-1.png"
              alt="company"
              loading="lazy"
              width={245}
              height={87}
              className="h-auto w-auto"
            />
            <Image
              src="/image/group-5.png"
              alt="company"
              loading="lazy"
              width={245}
              height={87}
              className="h-auto w-auto"
            />
            <Image
              src="/image/group-4.png"
              alt="company"
              loading="lazy"
              width={245}
              height={87}
              className="h-auto w-auto"
            />
            <Image
              src="/image/group-2.png"
              alt="company"
              loading="lazy"
              width={245}
              height={87}
              className="h-auto w-auto"
            />
            <Image
              src="/image/group-3.png"
              alt="company"
              loading="lazy"
              width={245}
              height={87}
              className="h-auto w-auto"
            />
          </div>
        </div>
      </Reveal>
    </div>
  );
};

export default Section6;
